<template>
  <v-list-group
    :color="gradient !== 1 ? 'white' : undefined"
    :group="group"
    :prepend-icon="getSubFields.icon"
    eager
    v-bind="$attrs"
    v-on:click="route"
  >
    <template v-slot:activator>
      <v-list-item-content v-if="getSubFields.title">
        <v-list-item-title v-text="getSubFields.title" />
      </v-list-item-content>
    </template>

    <!-- <template v-for="(child, i) in item.items">
      <default-list-group
        v-if="child.items"
        :key="`sub-group-${i}`"
        :item="child"
      />

      <default-list-item
        v-if="!child.items"
        :key="`child-${i}`"
        :item="child"
      />
    </template> -->

    <v-list-item
      class="ml-3"
      v-for="_child in getSubFields.items"
      :key="_child.title"
    >
      <v-list-item-content>
        <!-- <v-list-item-title v-text="_child.title"></v-list-item-title> -->
        <div
          v-text="_child.title"
          style="
            border-radius: 5px;
            background: #352703;
            color: #fad36e;
            padding: 7.5px;
          "
        ></div>
        <v-list-item v-for="child in _child.items" :key="child.title">
          <v-list-item-action v-if="(_child.title = 'Filters')">
            <v-checkbox
              v-model="taxiFilters"
              color="secondary"
              :value="child.id"
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <!-- <v-list-item-title v-text="child.title"></v-list-item-title> -->
            <div
              v-text="child.title"
              style="
                border-radius: 5px;
                background: #352703;
                color: #fad36e;
                padding: 3px 5px;
              "
            ></div>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-content>
    </v-list-item>
  </v-list-group>
</template>

<script>
// Utilities
import { get, sync } from "vuex-pathify";

export default {
  name: "DefaultListGroup",

  components: {
    DefaultListItem: () => import("./ListItem"),
  },

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    gradient: get("user/drawer@gradient"),
    ...sync("taxi", ["taxiFilters"]),
    group() {
      return this.genGroup(this.item.items);
    },
    title() {
      const matches = this.item.title.match(/\b(\w)/g);

      return matches.join("");
    },
    getTaxiMenu() {
      return this.$store.getters["taxi/getTaxisOptionMenu"];
    },
    getSubFields() {
      if (this.item.title === "Dashboard") {
        this.item.items.map((x) => {
          if (x.title === "Filters") {
            x.items = this.getTaxiMenu;
          }
        });
      }
      return this.item;
    },
  },

  methods: {
    genGroup(items) {
      return items
        .reduce((acc, cur) => {
          if (!cur.to) return acc;

          acc.push(cur.items ? this.genGroup(cur.items) : cur.to.slice(1, -1));

          return acc;
        }, [])
        .join("|");
    },
    route() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },
  },
};
</script>
